
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';
export default defineComponent({
  components: {
    draggable: VueDraggableNext
  },
  props: {
    headerText: {
      type: String,
      default: 'header'
    },
    list: {
      type: Array as PropType<{ id: number; name: string }[]>,
      default: () => {
        return []  
      }
    },
    options: {
      type: Object,
      default: () => {
        return null
      }
    }

  },
  setup(props) {
  }
})

